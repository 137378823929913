

















































import { ValidationObserver } from "vee-validate";
import { Component, Mixins } from "vue-property-decorator";
import {
  DefaBaseButton,
  DefaBaseRadioGroup,
  DefaBaseTextarea,
} from "@defa-as/components";
import type { InstallationReasonFailed } from "@defa-as/utils";
import StageGeneric from "@/components/stages/StageGeneric.vue";
import { StageId } from "@/store/model/progress";
import { optionsInstallationReasonFailed } from "@/config/options";
import { assignmentModule } from "@/store/modules/assignment-module";
import { progressModule } from "@/store/modules/progress-module";
import { orderModule } from "@/store/modules/order-module";
import ModalConfirmMixin from "@/mixins/modal-confirm.mixin";
import { fileUploadUrl } from "@/config";
import BaseModalConfirm from "@/components/common/base/BaseModalConfirm.vue";
import BaseReportSection from "@/components/common/base/BaseReportSection.vue";

@Component({
  components: {
    BaseReportSection,
    BaseModalConfirm,
    ValidationObserver,
    StageGeneric,
    DefaBaseRadioGroup,
    DefaBaseButton,
    DefaBaseTextarea,
  },
})
export default class StageReportInstallationFailed extends Mixins(
  ModalConfirmMixin
) {
  readonly optionsInstallationReasonFailed = optionsInstallationReasonFailed();

  get fileUploadUrl() {
    return fileUploadUrl(this.orderModule.id);
  }

  get orderModule() {
    return orderModule;
  }

  get assignmentModule() {
    return assignmentModule;
  }

  get comment() {
    return this.assignmentModule.installation.comment as string;
  }

  set comment(value: string) {
    this.assignmentModule.setInstallationProperty({
      property: "comment",
      value,
    });
  }

  get rulesComment() {
    return this.assignmentModule.installation.reasonFailed === "other"
      ? "required"
      : null;
  }

  onInstallationReasonFailedChange(value: InstallationReasonFailed) {
    this.assignmentModule.setInstallationProperty({
      property: "reasonFailed",
      value,
    });
  }

  async completeFailedReport() {
    this.hideModalConfirm();
    await progressModule.transitionStage({
      stageId: StageId.FAILED_INSTALLATION,
      event: "reportCompleted",
      payload: {
        installation: {
          reasonFailed: this.assignmentModule.installation.reasonFailed,
          comment: this.assignmentModule.installation.comment,
        },
      },
    });
  }
}
