import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import type {
  Assignment,
  AssignmentConfirmation,
  AssignmentFile,
  AssignmentInspection,
  AssignmentInstallation,
  AssignmentRejection,
  AssignmentTimestamps,
  ConfirmationInstallationPossible,
  InstallationSerialNumbers,
  Nullable,
  OrderLine,
} from "@defa-as/utils";
import { inDenmark, inFinland } from "@defa-as/utils";
import type { DefaDropzoneFile } from "@defa-as/components";
import store from "@/store";
import { loadingModule } from "@/store/modules/loading-module";
import { orderModule } from "@/store/modules/order-module";
import type {
  AssignmentMutationPayload,
  Local,
  PatchAssignmentPayload,
  UpdateSerialNumberPayload,
} from "@/store/model/assignment";
import router from "@/router";
import { apiPatchAssignment } from "@/http/requests";

@Module({ name: "assignment", namespaced: true, dynamic: true, store })
export class AssignmentModule extends VuexModule {
  data: Assignment = {
    rejection: {
      reason: null,
      comment: null,
    },
    confirmation: {
      correctWallbox: null,
      installationPossible: null,
      additionalServicesRequired: null,
      comment: null,
    },
    inspection: {
      scheduled: null,
      reasonFailed: null,
      comment: null,
    },
    installation: {
      scheduled: null,
      completed: null,
      files: [],
      reasonFailed: null,
      comment: null,
      serialNumbers: null,
      workingTimeInMinutes: null,
    },
    installationPartner: null,
    timestamps: {
      assignmentOpened: null,
      assignmentConfirmed: null,
      assignmentRejected: null,
      inspectionSkipped: null,
      inspectionRequired: null,
      inspectionScheduled: null,
      inspectionFailed: null,
      inspectionSuccessful: null,
      inspectionCompleted: null,
      consignmentRequested: null,
      consignmentSent: null,
      installationScheduled: null,
      consignmentCompleted: null,
      installationFailed: null,
      installationSuccessful: null,
      reportCompleted: null,
    },
  };

  // Not saved on backend
  local = {
    customerContacted: false,
    uploadedToHousingFolder: false,
  };

  get confirmation() {
    return this.data.confirmation;
  }

  get installation() {
    return this.data.installation;
  }

  get inspection() {
    return this.data.inspection;
  }

  get rejection() {
    return this.data.rejection;
  }

  get installationPartner() {
    return this.data.installationPartner;
  }

  get timestamps() {
    return this.data.timestamps;
  }

  get workingTimeRelevant() {
    return orderModule.isElliOrder || inDenmark() || inFinland();
  }

  get installationScheduledDate() {
    return this.installation.scheduled
      ? new Date(this.installation.scheduled)
      : null;
  }

  get installationCompletedDate() {
    return this.installation.completed
      ? new Date(this.installation.completed)
      : null;
  }

  get inspectionScheduledDate() {
    return this.inspection.scheduled
      ? new Date(this.inspection.scheduled)
      : null;
  }

  get serialNumberForOrderLine() {
    return (orderLine: OrderLine) => {
      if (this.installation.serialNumbers) {
        return this.installation.serialNumbers[orderLine.id];
      }
      return null;
    };
  }

  get installationFileIds() {
    if (this.installation.files) {
      return (this.installation.files as AssignmentFile[]).map(
        (file) => file.id
      );
    }
    return null;
  }

  get installationHasFiles() {
    return Boolean(this.installation.files?.length);
  }

  @Mutation
  setAssignmentData({ data }: { data: Assignment }) {
    this.data = data;
  }

  @Mutation
  setTimestamps({ timestamps }: { timestamps: AssignmentTimestamps }) {
    this.data.timestamps = timestamps;
  }

  @Mutation
  setInstallationProperty<K extends keyof AssignmentInstallation>(
    payload: AssignmentMutationPayload<AssignmentInstallation, K>
  ) {
    this.data.installation[payload.property] = payload.value;
  }

  @Mutation
  setInspectionProperty<K extends keyof AssignmentInspection>(
    payload: AssignmentMutationPayload<AssignmentInspection, K>
  ) {
    this.data.inspection[payload.property] = payload.value;
  }

  @Mutation
  setRejectionProperty<K extends keyof AssignmentRejection>(
    payload: AssignmentMutationPayload<AssignmentRejection, K>
  ) {
    this.data.rejection[payload.property] = payload.value;
  }

  @Mutation
  setConfirmationProperty<K extends keyof AssignmentConfirmation>(
    payload: AssignmentMutationPayload<AssignmentConfirmation, K>
  ) {
    this.data.confirmation[payload.property] = payload.value;
  }

  @Mutation
  setLocalProperty<K extends keyof Local>(
    payload: AssignmentMutationPayload<Local, K>
  ) {
    this.local[payload.property] = payload.value;
  }

  @Mutation
  updateInstallationSerialNumber({
    id,
    serialNumber,
  }: UpdateSerialNumberPayload) {
    this.data.installation.serialNumbers = {
      ...this.data.installation.serialNumbers,
      [id]: serialNumber,
    };
  }

  @Mutation
  setInstallationFiles(files: DefaDropzoneFile[]) {
    this.data.installation.files = files;
  }

  @Action
  async updateConfirmationCorrectWallbox({ value }: { value: boolean }) {
    this.setConfirmationProperty({ property: "correctWallbox", value });
    if (!value) {
      await this.updateConfirmationInstallationPossible({ value: null });
    } else {
      this.setConfirmationProperty({ property: "comment", value: null });
    }
  }

  @Action
  async updateConfirmationInstallationPossible({
    value,
  }: {
    value: Nullable<ConfirmationInstallationPossible>;
  }) {
    this.setConfirmationProperty({ property: "installationPossible", value });
    if (value === "no" || value === null) {
      await this.updateConfirmationAdditionalServicesRequired({ value: null });
    } else {
      this.setConfirmationProperty({ property: "comment", value: null });
    }
  }

  @Action
  async updateConfirmationAdditionalServicesRequired({
    value,
  }: {
    value: Nullable<boolean>;
  }) {
    this.setConfirmationProperty({
      property: "additionalServicesRequired",
      value,
    });
  }

  @Action
  async patchAssignment(payload: PatchAssignmentPayload) {
    loadingModule.setUpdateLoading();
    try {
      const assignment = await apiPatchAssignment(payload, {
        orderId: router.currentRoute.params.orderId,
        secret: router.currentRoute.params.secret,
      });
      this.setAssignmentData({ data: assignment });
    } finally {
      loadingModule.unsetUpdateLoading();
    }
  }

  @Action
  async initializeInstallation() {
    if (!this.data.installation.completed && this.data.installation.scheduled) {
      this.setInstallationProperty({
        property: "completed",
        value: this.data.installation.scheduled,
      });
    }
    const serialNumbers = orderModule.orderLines.reduce(
      (
        currentSerialNumbers: NonNullable<InstallationSerialNumbers>,
        orderLine
      ) => {
        // Don't overwrite serial number if already set
        currentSerialNumbers[orderLine.id] =
          this.data.installation.serialNumbers?.[orderLine.id] ||
          orderLine.serialNumberConsigned;
        return currentSerialNumbers;
      },
      {}
    );
    this.setInstallationProperty({
      property: "serialNumbers",
      value: serialNumbers,
    });
  }
}

export const assignmentModule = getModule(AssignmentModule);
