























import { Component, Mixins } from "vue-property-decorator";
import { DefaBaseButton } from "@defa-as/components";
import { StageId } from "@/store/model/progress";
import StageGeneric from "@/components/stages/StageGeneric.vue";
import ModalConfirmMixin from "@/mixins/modal-confirm.mixin";
import { progressModule } from "@/store/modules/progress-module";
import BaseModalConfirm from "@/components/common/base/BaseModalConfirm.vue";

@Component({
  components: {
    BaseModalConfirm,
    StageGeneric,
    DefaBaseButton,
  },
})
export default class StageInspectionCompleted extends Mixins(
  ModalConfirmMixin
) {
  async failInspection() {
    await progressModule.transitionStage({
      stageId: StageId.REPORT_INSPECTION_FAILED,
    });
  }

  async startConsignment() {
    this.hideModalConfirm();
    await progressModule.transitionStage({
      stageId: StageId.CONSIGNMENT_STARTED,
      event: "inspectionSuccessful",
    });
  }
}
