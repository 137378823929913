






















import { Component, Vue } from "vue-property-decorator";
import { compareAsc, parseISO } from "date-fns";
import SectionExpandable from "@/components/common/SectionExpandable.vue";
import { assignmentModule } from "@/store/modules/assignment-module";

@Component({
  components: {
    SectionExpandable,
  },
})
export default class CustomerChangelog extends Vue {
  get assignmentModule() {
    return assignmentModule;
  }

  get timestampsSortedAscending() {
    return Object.entries(this.assignmentModule.timestamps)
      .filter((entry) => !!entry[1]) // Filter out null values
      .sort((timestampOneEntry, timestampTwoEntry) => {
        const timestampOne = parseISO(timestampOneEntry[1]);
        const timestampTwo = parseISO(timestampTwoEntry[1]);
        return compareAsc(timestampOne, timestampTwo);
      })
      .reduce(
        (sortedTimestamps, timestampEntry) => ({
          ...sortedTimestamps,
          [timestampEntry[0]]: timestampEntry[1],
        }),
        {}
      );
  }
}
