






































































import { Component, Mixins } from "vue-property-decorator";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { DefaBaseButton, DefaBaseInput } from "@defa-as/components";
import type { DeliveryAddress } from "@defa-as/utils";
import { getPlaceForPostalCode } from "@defa-as/utils";
import { assignmentModule } from "@/store/modules/assignment-module";
import { orderModule } from "@/store/modules/order-module";
import ModalConfirmMixin from "@/mixins/modal-confirm.mixin";
import BaseModalConfirm from "@/components/common/base/BaseModalConfirm.vue";

@Component({
  components: {
    DefaBaseInput,
    DefaBaseButton,
    BaseModalConfirm,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class StageConsignmentStartedDeliveryAddress extends Mixins(
  ModalConfirmMixin
) {
  localDeliveryAddress = this.originalDeliveryAddress;

  get orderModule() {
    return orderModule;
  }

  get assignmentModule() {
    return assignmentModule;
  }

  get originalDeliveryAddress() {
    if (this.orderModule.isDeliveryAddressSet) {
      return {
        ...(this.orderModule.deliveryAddress as DeliveryAddress),
      };
    }
    return {
      recipient: this.assignmentModule.installationPartner?.companyName || "",
      street: this.assignmentModule.installationPartner?.addressStreet1 || "",
      postalCode: this.assignmentModule.installationPartner?.addressZip || "",
      town: this.assignmentModule.installationPartner?.addressCity || "",
    };
  }

  get postalCode() {
    return this.localDeliveryAddress.postalCode;
  }

  set postalCode(postalCode: string) {
    this.localDeliveryAddress.postalCode = postalCode;
    this.setCustomerPlace(postalCode);
  }

  async setCustomerPlace(postalCode: string) {
    this.localDeliveryAddress.town = await getPlaceForPostalCode(postalCode);
  }

  onCancel() {
    this.localDeliveryAddress = {
      ...this.originalDeliveryAddress,
    };
  }

  async onConfirm() {
    this.hideModalConfirm();
    await orderModule.patchOrder({
      deliveryAddress: this.localDeliveryAddress,
    });
  }
}
