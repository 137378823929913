import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import {
  AuthorizationRequirement,
  registerAuthorizationNavigationGuard,
} from "@/router/navigation-guard";
import ViewInstallation from "@/views/ViewInstallation.vue";
import { userModule } from "@/store/modules/user-module";

Vue.use(VueRouter);

export const ROUTE_NAMES = {
  ROOT: "Root",
  LOGIN: "Login",
  REGISTRATION: {
    INITIAL: "RegistrationInitial",
    CONFIRMATION: "RegistrationConfirmation",
  },
  PASSWORD_RECOVERY: {
    INITIAL: "PasswordRecoveryInitial",
    CONFIRMATION: "PasswordRecoveryConfirmation",
  },
  INSTALLATION: {
    LIST: "InstallationList",
    DETAILS: "InstallationDetails",
  },
  PROFILE: "Profile",
  NOT_FOUND: "NotFound",
  ERROR: "Error",
};

type RouteConfigWithOptionalAuthorization = RouteConfig & {
  meta?: {
    authorization?: AuthorizationRequirement;
  };
};

export const routes: Array<RouteConfigWithOptionalAuthorization> = [
  {
    path: "/",
    name: ROUTE_NAMES.ROOT,
    redirect: () => {
      if (userModule.isLoggedIn) {
        return {
          name: ROUTE_NAMES.INSTALLATION.LIST,
        };
      }
      return {
        name: ROUTE_NAMES.LOGIN,
      };
    },
  },
  {
    path: "/login",
    name: ROUTE_NAMES.LOGIN,
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/ViewLogin.vue"),
    meta: {
      authorization: "FORBID_TOKEN" as AuthorizationRequirement,
    },
  },
  {
    path: "/register",
    name: ROUTE_NAMES.REGISTRATION.INITIAL,
    component: () =>
      import(
        /* webpackChunkName: "registerUser" */ "@/views/ViewRegistration.vue"
      ),
    meta: {
      authorization: "FORBID_TOKEN" as AuthorizationRequirement,
    },
  },
  {
    path: "/register/confirm/:hash",
    name: ROUTE_NAMES.REGISTRATION.CONFIRMATION,
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "registerUser" */ "@/views/ViewRegistrationConfirmation.vue"
      ),
    meta: {
      authorization: "FORBID_TOKEN" as AuthorizationRequirement,
    },
  },
  {
    path: "/recover-password",
    name: ROUTE_NAMES.PASSWORD_RECOVERY.INITIAL,
    component: () =>
      import(
        /* webpackChunkName: "passwordRecovery" */ "@/views/ViewPasswordRecovery.vue"
      ),
    meta: {
      authorization: "FORBID_TOKEN" as AuthorizationRequirement,
    },
  },
  {
    path: "/recover-password/confirm/:hash",
    name: ROUTE_NAMES.PASSWORD_RECOVERY.CONFIRMATION,
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "passwordRecoveryConfirmation" */ "@/views/ViewPasswordRecoveryConfirmation.vue"
      ),
    meta: {
      authorization: "FORBID_TOKEN" as AuthorizationRequirement,
    },
  },
  {
    path: "/installations",
    name: ROUTE_NAMES.INSTALLATION.LIST,
    component: () =>
      import(
        /* webpackChunkName: "installationList" */ "@/views/ViewInstallationList.vue"
      ),
    props: (route) => ({ ...route.query }),
    meta: {
      authorization: "REQUIRE_TOKEN" as AuthorizationRequirement,
    },
  },
  {
    path: "/user/profile",
    name: ROUTE_NAMES.PROFILE,
    component: () =>
      import(/* webpackChunkName: "profile" */ "@/views/ViewProfile.vue"),
    meta: {
      authorization: "REQUIRE_TOKEN" as AuthorizationRequirement,
    },
  },
  {
    path: "/:orderId(\\d{5,})/:secret?",
    name: ROUTE_NAMES.INSTALLATION.DETAILS,
    props: true,
    component: ViewInstallation,
    beforeEnter: (to, __, next) => {
      if (!userModule.isLoggedIn && !to.params.secret) {
        return next({
          name: ROUTE_NAMES.NOT_FOUND,
          params: {
            orderId: to.params.orderId,
          },
        });
      }
      next();
    },
  },
  {
    path: "/not-found",
    name: ROUTE_NAMES.NOT_FOUND,
    component: () =>
      import(/* webpackChunkName: "notFound" */ "@/views/ViewNotFound.vue"),
  },
  {
    path: "/error",
    name: ROUTE_NAMES.ERROR,
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/ViewError.vue"),
  },
  {
    path: "*",
    redirect: { name: ROUTE_NAMES.NOT_FOUND },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

registerAuthorizationNavigationGuard(router);

export default router;
