import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";
import type {
  Order,
  OrderState,
  OrderWithHomecheckAndAssignment,
} from "@defa-as/utils";
import {
  defaRetailerGroupHandle,
  isElliOrder,
  fromUrlToApplication,
} from "@defa-as/utils";
import type { PatchOrderPayload } from "@/store/model/order";
import { loadingModule } from "@/store/modules/loading-module";
import router, { ROUTE_NAMES } from "@/router";
import { homecheckModule } from "@/store/modules/homecheck-module";
import { assignmentModule } from "@/store/modules/assignment-module";
import { progressModule } from "@/store/modules/progress-module";
import { apiPatchAssignment, apiPatchOrder, getOrder } from "@/http/requests";

type ExcludedOrderProps =
  | "orderStatus"
  | "retailerSalesPerson"
  | "retailerOrderRef"
  | "approvedByHouseOwner"
  | "administratorNotes"
  | "state"
  | "created"
  | "updated";

type StateWithJustRemoteHomeCheckCompleted = {
  state: Pick<OrderState, "remoteHomeCheckCompleted">;
};

@Module({ name: "order", namespaced: true, store, dynamic: true })
export class OrderModule extends VuexModule {
  data: Omit<Order, ExcludedOrderProps> &
    StateWithJustRemoteHomeCheckCompleted = {
    id: "",
    secret: "",
    title: "",
    orderNotes: "",
    customerFullName: "",
    customerAddressLine1: "",
    customerEmail: "",
    customerPlace: "",
    customerPostcode: "",
    customerPhone: "",
    customerCarBrand: "",
    customerCarModel: "",
    customerCarDeliveryDate: null,
    customerGrantRequested: false,
    orderLines: [],
    retailer: null,
    deliveryAddress: null,
    tracking: [],
    state: {
      remoteHomeCheckCompleted: {
        state: false,
        dateUpdated: null,
        loading: false,
      },
    },
  };

  get id() {
    return this.data.id;
  }

  get secret() {
    return this.data.secret;
  }

  get title() {
    return this.data.title;
  }

  get orderNotes() {
    return this.data.orderNotes;
  }

  get customerFullName() {
    return this.data.customerFullName;
  }

  get customerAddressLine1() {
    return this.data.customerAddressLine1;
  }

  get customerEmail() {
    return this.data.customerEmail;
  }

  get customerPlace() {
    return this.data.customerPlace;
  }

  get customerPostcode() {
    return this.data.customerPostcode;
  }

  get customerPhone() {
    return this.data.customerPhone;
  }

  get customerCarBrand() {
    return this.data.customerCarBrand;
  }

  get customerCarModel() {
    return this.data.customerCarModel;
  }

  get customerCarDeliveryDate() {
    return this.data.customerCarDeliveryDate?.date;
  }

  get customerGrantRequested() {
    return this.data.customerGrantRequested;
  }

  get orderLines() {
    return this.data.orderLines;
  }

  get hasNonDefaRetailer() {
    return this.retailerGroup?.handle !== defaRetailerGroupHandle;
  }

  get retailerGroup() {
    return this.retailer?.group;
  }

  get retailer() {
    return this.data.retailer;
  }

  get retailerName() {
    return this.data.retailer?.name;
  }

  get deliveryAddress() {
    return this.data.deliveryAddress;
  }

  get isElliOrder() {
    return this.retailerName ? isElliOrder(this.retailerName) : false;
  }

  get isDeliveryAddressEditable() {
    if (this.retailer) {
      return this.isElliOrder && !this.isDeliveryAddressSet;
    }
    return false;
  }

  get tracking() {
    return this.data.tracking;
  }

  get isRemoteHomecheckCompleted() {
    return this.data.state.remoteHomeCheckCompleted.state;
  }

  get isDeliveryAddressSet() {
    if (this.deliveryAddress) {
      return Object.values(this.deliveryAddress).every(Boolean);
    }
    return false;
  }

  get customerPostcodeAndPlace() {
    return `${this.customerPostcode} ${this.customerPlace}`;
  }

  get titleWithoutHash() {
    return this.title?.replace("#", "");
  }

  get hasTrackingInfo() {
    return Boolean(this.tracking.length);
  }

  @Mutation
  setOrderData({ data }: { data: Order }) {
    this.data = data;
  }

  @Action
  async setOrderDataWithoutAssignmentAndHomecheck({
    data,
  }: {
    data: OrderWithHomecheckAndAssignment;
  }) {
    const { assignment, homecheck, ...order } = data;
    this.setOrderData({ data: order });
  }

  @Action
  async loadOrder({ orderId, secret }: { orderId: string; secret?: string }) {
    loadingModule.setInitializationLoading();
    try {
      const order = await getOrder(orderId, secret);
      await this.setOrderDataWithoutAssignmentAndHomecheck({ data: order });
      // Set "assignmentOpened" if not already set
      if (
        !order.assignment.timestamps.assignmentOpened &&
        fromUrlToApplication(document.referrer) !== "EP"
      ) {
        order.assignment = await apiPatchAssignment(
          {
            event: "assignmentOpened",
          },
          {
            orderId: router.currentRoute.params.orderId,
            secret: router.currentRoute.params.secret,
          }
        );
      }
      homecheckModule.setHomecheckData({ data: order.homecheck });
      assignmentModule.setAssignmentData({ data: order.assignment });
      await progressModule.initializeStage({
        timestamps: order.assignment.timestamps,
      });
    } catch (e) {
      if (e.response?.status === 404) {
        return await router.push({
          name: ROUTE_NAMES.NOT_FOUND,
          query: {
            orderId,
            ...(secret ? { secret } : null),
          },
        });
      }
      throw e;
    } finally {
      loadingModule.unsetInitializationLoading();
    }
  }

  @Action
  async patchOrder(payload: PatchOrderPayload) {
    loadingModule.setUpdateLoading();
    try {
      const order = await apiPatchOrder(payload, {
        orderId: router.currentRoute.params.orderId,
        secret: router.currentRoute.params.secret,
      });
      await this.setOrderDataWithoutAssignmentAndHomecheck({ data: order });
    } finally {
      loadingModule.unsetUpdateLoading();
    }
  }
}

export const orderModule = getModule(OrderModule);
