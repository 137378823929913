































import { Component, Vue } from "vue-property-decorator";
import { formatDatetimeShort } from "@defa-as/utils";
import StageGeneric from "@/components/stages/StageGeneric.vue";
import StageFailedHeader from "@/components/stages/failed/StageFailedHeader.vue";
import { assignmentModule } from "@/store/modules/assignment-module";
import BaseReportSection from "@/components/common/base/BaseReportSection.vue";

@Component({
  components: {
    BaseReportSection,
    StageFailedHeader,
    StageGeneric,
  },
})
export default class StageFailedInspection extends Vue {
  get assignmentModule() {
    return assignmentModule;
  }

  get failedInspectionParagraph() {
    if (this.assignmentModule.inspectionScheduledDate) {
      return this.$t("stages.failedInspection.paragraphWithDate", {
        datetime: formatDatetimeShort(
          this.assignmentModule.inspectionScheduledDate
        ),
      });
    }
    return this.$t("stages.failedInspection.paragraph");
  }
}
