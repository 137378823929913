

















































import { Component, Vue } from "vue-property-decorator";
import { durationToHoursAndMinutes } from "@defa-as/utils";
import { DefaBaseOrderLine } from "@defa-as/components";
import StageGeneric from "@/components/stages/StageGeneric.vue";
import StageReportCompletedHeader from "@/components/stages/report/StageReportCompletedHeader.vue";
import { orderModule } from "@/store/modules/order-module";
import { assignmentModule } from "@/store/modules/assignment-module";
import BaseFiles from "@/components/common/base/BaseFiles.vue";
import BaseReportSection from "@/components/common/base/BaseReportSection.vue";

@Component({
  components: {
    BaseReportSection,
    StageGeneric,
    StageReportCompletedHeader,
    DefaBaseOrderLine,
    BaseFiles,
  },
})
export default class StageReportCompleted extends Vue {
  get workingTimeInMinutesFormatted() {
    if (assignmentModule.installation.workingTimeInMinutes) {
      const { hours, minutes } = durationToHoursAndMinutes(
        assignmentModule.installation.workingTimeInMinutes
      );
      return this.$t("shared.formats.durationHoursAndMinutes", {
        hours,
        minutes,
      });
    }
    return null;
  }

  get orderModule() {
    return orderModule;
  }

  get assignmentModule() {
    return assignmentModule;
  }
}
