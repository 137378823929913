























import { Component, Vue } from "vue-property-decorator";
import {
  DefaLanguageSwitcher,
  DefaLoaderBar,
  DefaNotificationError,
  DefaTestHeader,
  DefaFullPageLoader,
  DefaFooter,
} from "@defa-as/components";
import { setMainAndBackupTranslations } from "@defa-as/utils";
import vueI18n from "@/i18n";
import { errorModule } from "@/store/modules/error-module";
import { loadingModule } from "@/store/modules/loading-module";
import TheHeader from "@/components/TheHeader.vue";
import { userModule } from "@/store/modules/user-module";
import { orderModule } from "@/store/modules/order-module";
import { ROUTE_NAMES } from "@/router";

@Component({
  components: {
    DefaNotificationError,
    DefaLoaderBar,
    DefaTestHeader,
    DefaLanguageSwitcher,
    DefaFullPageLoader,
    DefaFooter,
    TheHeader,
  },
})
export default class App extends Vue {
  languageLoading = false;

  async created() {
    try {
      this.languageLoading = true;
      await setMainAndBackupTranslations(vueI18n);
    } finally {
      this.languageLoading = false;
    }
    await userModule.refreshUser();
  }

  get onInstallationRoute() {
    return this.$route.name === ROUTE_NAMES.INSTALLATION.DETAILS;
  }

  get orderRetailerGroupHandle() {
    return this.onInstallationRoute ? orderModule.retailerGroup?.handle : null;
  }

  get orderRetailerGroupName() {
    return this.onInstallationRoute ? orderModule.retailerGroup?.name : null;
  }

  get userModule() {
    return userModule;
  }

  get loadingModule() {
    return loadingModule;
  }

  get errorModule() {
    return errorModule;
  }
}
