



































import { Component, Vue } from "vue-property-decorator";
import { formatDatetimeShort } from "@defa-as/utils";
import StageGeneric from "@/components/stages/StageGeneric.vue";
import StageFailedHeader from "@/components/stages/failed/StageFailedHeader.vue";
import { assignmentModule } from "@/store/modules/assignment-module";
import BaseFiles from "@/components/common/base/BaseFiles.vue";
import BaseReportSection from "@/components/common/base/BaseReportSection.vue";

@Component({
  components: {
    BaseReportSection,
    StageFailedHeader,
    StageGeneric,
    BaseFiles,
  },
})
export default class StageFailedInstallation extends Vue {
  get assignmentModule() {
    return assignmentModule;
  }

  get failedInstallationParagraph() {
    if (this.assignmentModule.installationScheduledDate) {
      return this.$t("stages.failedInstallation.paragraph", {
        datetime: formatDatetimeShort(
          this.assignmentModule.installationScheduledDate
        ),
      });
    }
    return this.$t("stages.failedInstallation.paragraphNoDate");
  }
}
