

































import { Component, Mixins } from "vue-property-decorator";
import { DefaBaseButton } from "@defa-as/components";
import StageGeneric from "@/components/stages/StageGeneric.vue";
import { StageId } from "@/store/model/progress";
import { assignmentModule } from "@/store/modules/assignment-module";
import { progressModule } from "@/store/modules/progress-module";
import ModalConfirmMixin from "@/mixins/modal-confirm.mixin";
import StageConsignmentStartedDeliveryAddress from "@/components/stages/consigment/StageConsignmentStartedDeliveryAddress.vue";
import BaseModalConfirm from "@/components/common/base/BaseModalConfirm.vue";
import { orderModule } from "@/store/modules/order-module";

@Component({
  components: {
    BaseModalConfirm,
    StageConsignmentStartedDeliveryAddress,
    StageGeneric,
    DefaBaseButton,
  },
})
export default class StageConsignmentStarted extends Mixins(ModalConfirmMixin) {
  get assignmentModule() {
    return assignmentModule;
  }

  async completeConsignmentStarted() {
    this.hideModalConfirm();
    await progressModule.transitionStage({
      stageId: StageId.CONSIGNMENT_SENT,
      event: "consignmentRequested",
    });
  }

  async onClickNext(handleSubmit: (fn: () => void) => void) {
    if (orderModule.isDeliveryAddressEditable) {
      return handleSubmit(this.showModalConfirm);
    }
    this.showModalConfirm();
  }
}
