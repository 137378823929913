import {
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";

@Module({ name: "error", namespaced: true, dynamic: true, store })
export class ErrorModule extends VuexModule {
  error = "";

  get hasError() {
    return Boolean(this.error);
  }

  @Mutation
  clearError() {
    this.error = "";
  }

  @Mutation
  setError({ error }: { error: string }) {
    this.error = error;
  }
}

export const errorModule = getModule(ErrorModule);
