






















































import { ValidationObserver } from "vee-validate";
import { Component, Mixins } from "vue-property-decorator";
import {
  DefaBaseButton,
  DefaBaseRadioGroup,
  DefaBaseTextarea,
} from "@defa-as/components";
import type { InspectionReasonFailed } from "@defa-as/utils";
import StageGeneric from "@/components/stages/StageGeneric.vue";
import { StageId } from "@/store/model/progress";
import { optionsInspectionReasonFailed } from "@/config/options";
import { assignmentModule } from "@/store/modules/assignment-module";
import { progressModule } from "@/store/modules/progress-module";
import ModalConfirmMixin from "@/mixins/modal-confirm.mixin";
import BaseModalConfirm from "@/components/common/base/BaseModalConfirm.vue";
import BaseReportSection from "@/components/common/base/BaseReportSection.vue";

@Component({
  components: {
    BaseReportSection,
    BaseModalConfirm,
    ValidationObserver,
    StageGeneric,
    DefaBaseButton,
    DefaBaseTextarea,
    DefaBaseRadioGroup,
  },
})
export default class StageInspectionFailed extends Mixins(ModalConfirmMixin) {
  readonly optionsInspectionReasonFailed = optionsInspectionReasonFailed();

  get assignmentModule() {
    return assignmentModule;
  }

  get comment() {
    return this.assignmentModule.inspection.comment as string;
  }

  set comment(value: string) {
    this.assignmentModule.setInspectionProperty({ property: "comment", value });
  }

  get rulesComment() {
    return this.assignmentModule.inspection.reasonFailed === "other"
      ? "required"
      : null;
  }

  onChangeInspectionFailedReason(value: InspectionReasonFailed) {
    this.assignmentModule.setInspectionProperty({
      property: "reasonFailed",
      value,
    });
  }

  async goBack() {
    await progressModule.transitionStage({
      stageId: StageId.INSPECTION_COMPLETED,
    });
  }

  async failAssignment() {
    this.hideModalConfirm();
    await progressModule.transitionStage({
      stageId: StageId.FAILED_INSPECTION,
      event: "inspectionFailed",
      payload: {
        inspection: {
          reasonFailed: this.assignmentModule.inspection.reasonFailed,
          comment: this.assignmentModule.inspection.comment,
        },
      },
    });
  }
}
