









import { Component, Vue } from "vue-property-decorator";
import StageReportCompletedHeaderIcon from "@/components/stages/report/StageReportCompletedHeaderIcon.vue";
import { orderModule } from "@/store/modules/order-module";

@Component({
  components: { StageReportCompletedHeaderIcon },
})
export default class StageReportCompletedHeader extends Vue {
  get orderModule() {
    return orderModule;
  }
}
