import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";
import { loadingModule } from "@/store/modules/loading-module";
import type { IPVUser } from "@/store/model/user";
import { setUserForSentry } from "@defa-as/utils";
import type { Nullable } from "@defa-as/utils";
import {
  changePassword,
  getUserInfo,
  login,
  recoverPassword,
  registerUser,
  resetPassword,
} from "@/http/requests";

const initialState = (): IPVUser => ({
  id: "",
  token: "",
  email: "",
  groups: [],
});

@Module({
  name: "user",
  dynamic: true,
  store,
  preserveState: !!store.state.user,
})
export class UserModule extends VuexModule {
  model = initialState();

  passwordChangeSuccess = {
    value: false,
    timeoutId: null as Nullable<number>,
  };

  get role() {
    if (this.model.groups.includes("installationPartnerViewAdmin")) {
      return "installationPartnerViewAdmin";
    }
    return "installationPartner";
  }

  get isAdmin() {
    return this.role === "installationPartnerViewAdmin";
  }

  get token() {
    return this.model.token;
  }

  get email() {
    return this.model.email;
  }

  get isLoggedIn() {
    return Boolean(this.model.token);
  }

  get isPasswordChangeSuccessful() {
    return this.passwordChangeSuccess.value;
  }

  @Mutation
  setModel(model: IPVUser) {
    this.model = model;
  }

  @Mutation
  clear() {
    this.model = initialState();
  }

  @Mutation
  setPasswordChangeSuccessValue() {
    this.passwordChangeSuccess.value = true;
  }

  @Mutation
  unsetPasswordChangeSuccessValue() {
    this.passwordChangeSuccess.value = false;
  }

  @Mutation
  setPasswordChangeSuccessTimeoutId(timeoutId: Nullable<number>) {
    this.passwordChangeSuccess.timeoutId = timeoutId;
  }

  @Mutation
  unsetPasswordChangeSuccessTimeoutId() {
    this.passwordChangeSuccess.timeoutId = null;
  }

  @Action
  async setUser({ user }: { user: IPVUser }) {
    this.setModel(user);
    setUserForSentry(user);
  }

  @Action
  async login({ email, password }: { email: string; password: string }) {
    const user = await login({
      loginName: email,
      password,
    });
    await this.setUser({ user });
  }

  @Action
  async refreshUser() {
    if (this.isLoggedIn) {
      const user = await getUserInfo();
      await this.setUser({ user });
    }
  }

  @Action
  async register({ email, password }: { email: string; password: string }) {
    try {
      loadingModule.setUpdateLoading();
      await registerUser({
        email,
        password,
      });
    } finally {
      loadingModule.unsetUpdateLoading();
    }
  }

  @Action
  async changePassword({ password }: { password: string }) {
    if (this.passwordChangeSuccess.timeoutId) {
      clearTimeout(this.passwordChangeSuccess.timeoutId);
      this.unsetPasswordChangeSuccessTimeoutId();
      this.unsetPasswordChangeSuccessValue();
    }
    try {
      loadingModule.setUpdateLoading();
      await changePassword({ newPassword: password });
      this.setPasswordChangeSuccessValue();
      this.setPasswordChangeSuccessTimeoutId(
        setTimeout(() => {
          this.unsetPasswordChangeSuccessValue();
          this.unsetPasswordChangeSuccessTimeoutId();
        }, 5000)
      );
    } finally {
      loadingModule.unsetUpdateLoading();
    }
  }

  @Action
  async resetPassword({
    newPassword,
    hash,
  }: {
    newPassword: string;
    hash: string;
  }) {
    try {
      loadingModule.setUpdateLoading();
      await resetPassword({
        newPassword,
        hash,
      });
    } finally {
      loadingModule.unsetUpdateLoading();
    }
  }

  @Action
  async recoverPassword({ email }: { email: string }) {
    try {
      loadingModule.setUpdateLoading();
      await recoverPassword({ email });
    } finally {
      loadingModule.unsetUpdateLoading();
    }
  }

  @Action
  async logout() {
    this.clear();
  }
}

export const userModule = getModule(UserModule);
