











import { Component, Vue } from "vue-property-decorator";
import { DefaTrackingInformation } from "@defa-as/components";
import { orderModule } from "@/store/modules/order-module";
import SectionExpandable from "@/components/common/SectionExpandable.vue";

@Component({
  components: {
    SectionExpandable,
    DefaTrackingInformation,
  },
})
export default class SectionTrackingInfo extends Vue {
  get orderModule() {
    return orderModule;
  }
}
