







































import { Component, Mixins } from "vue-property-decorator";
import { inNorway } from "@defa-as/utils";
import { DefaBaseButton } from "@defa-as/components";
import StageGeneric from "@/components/stages/StageGeneric.vue";
import { StageId } from "@/store/model/progress";
import { orderModule } from "@/store/modules/order-module";
import { progressModule } from "@/store/modules/progress-module";
import ModalConfirmMixin from "@/mixins/modal-confirm.mixin";
import BaseModalConfirm from "@/components/common/base/BaseModalConfirm.vue";

@Component({
  components: { StageGeneric, DefaBaseButton, BaseModalConfirm },
})
export default class StageAssignmentOpened extends Mixins(ModalConfirmMixin) {
  readonly inNorway = inNorway();

  get orderModule() {
    return orderModule;
  }

  async confirmAssignment() {
    this.hideModalConfirm();
    await progressModule.transitionStage({
      stageId: StageId.ASSIGNMENT_CONFIRMED,
      event: "assignmentConfirmed",
    });
  }

  async rejectAssignment() {
    await progressModule.transitionStage({
      stageId: StageId.ASSIGNMENT_REJECTED,
    });
  }
}
