import { Component, Vue } from "vue-property-decorator";
import { Nullable } from "@defa-as/utils";

interface ConfirmAction {
  (): Promise<void>;
}

@Component
export default class ModalConfirmMixin extends Vue {
  modalConfirmVisible = false;
  confirmAction: Nullable<ConfirmAction> = null;

  showModalConfirm(confirmAction?: ConfirmAction) {
    this.modalConfirmVisible = true;
    if (confirmAction) {
      this.confirmAction = confirmAction;
    }
  }

  hideModalConfirm() {
    this.modalConfirmVisible = false;
    if (this.confirmAction) {
      this.confirmAction = null;
    }
  }
}
