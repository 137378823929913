














































































import { ValidationObserver } from "vee-validate";
import { Component, Mixins } from "vue-property-decorator";
import type { DefaDropzoneFile } from "@defa-as/components";
import {
  DefaBaseButton,
  DefaBaseCheckbox,
  DefaBaseDatetimeInput,
  DefaBaseDropzoneWithValidation,
  DefaBaseDurationInputWithValidation,
} from "@defa-as/components";
import { formatDatetimeShort } from "@defa-as/utils";
import StageGeneric from "@/components/stages/StageGeneric.vue";
import StageReportOrderLines from "@/components/stages/report/StageReportOrderLines.vue";
import { StageId } from "@/store/model/progress";
import { assignmentModule } from "@/store/modules/assignment-module";
import { progressModule } from "@/store/modules/progress-module";
import { orderModule } from "@/store/modules/order-module";
import ModalConfirmMixin from "@/mixins/modal-confirm.mixin";
import { fileUploadUrl } from "@/config";
import BaseReportSection from "@/components/common/base/BaseReportSection.vue";
import BaseModalConfirm from "@/components/common/base/BaseModalConfirm.vue";

@Component({
  components: {
    BaseModalConfirm,
    BaseReportSection,
    DefaBaseDurationInputWithValidation,
    DefaBaseDatetimeInput,
    DefaBaseDropzoneWithValidation,
    StageGeneric,
    StageReportOrderLines,
    DefaBaseButton,
    DefaBaseCheckbox,
    ValidationObserver,
  },
})
export default class StageReportInstallationSuccessful extends Mixins(
  ModalConfirmMixin
) {
  get orderModule() {
    return orderModule;
  }

  get assignmentModule() {
    return assignmentModule;
  }

  get fileUploadUrl() {
    return fileUploadUrl(this.orderModule.id);
  }

  get uploadedToHousingFolder() {
    return assignmentModule.local.uploadedToHousingFolder;
  }

  set uploadedToHousingFolder(value: boolean) {
    assignmentModule.setLocalProperty({
      property: "uploadedToHousingFolder",
      value,
    });
  }

  get workingTimeInMinutes() {
    return assignmentModule.installation.workingTimeInMinutes as number;
  }

  set workingTimeInMinutes(workingTimeInMinutes: number) {
    assignmentModule.setInstallationProperty({
      property: "workingTimeInMinutes",
      value: workingTimeInMinutes,
    });
  }

  get rulesInstallationFiles() {
    return "required";
  }

  get rulesWorkingTimeInMinutes() {
    return this.orderModule.isElliOrder ? "required|min_value:15" : null;
  }

  get isWorkingTimeInMinutesVisible() {
    return assignmentModule.workingTimeRelevant;
  }

  get workingTimeInMinutesCustomMessages() {
    return {
      required: this.$t("veeValidate.customMessages.workingTimeInMinutes"),
      min_value: this.$t("veeValidate.customMessages.workingTimeInMinutes"),
    };
  }

  get installationDateCompletedPlaceholder() {
    if (this.assignmentModule.installationCompletedDate) {
      return this.$t("stages.common.installationCompleted", {
        datetime: formatDatetimeShort(
          this.assignmentModule.installationCompletedDate
        ),
      });
    }
    return this.$t("stages.common.toCompleteInstallation");
  }

  async created() {
    await this.assignmentModule.initializeInstallation();
  }

  onChange(value: Date) {
    this.assignmentModule.setInstallationProperty({
      property: "completed",
      value: value,
    });
  }

  onDropzoneInput(files: DefaDropzoneFile[]) {
    this.assignmentModule.setInstallationFiles(files);
  }

  async completeReport() {
    this.hideModalConfirm();
    await progressModule.transitionStage({
      stageId: StageId.REPORT_COMPLETED,
      event: "reportCompleted",
      payload: {
        installation: {
          completed: this.assignmentModule.installation.completed,
          serialNumbers: this.assignmentModule.installation.serialNumbers,
          files: this.assignmentModule.installationFileIds,
          workingTimeInMinutes: this.assignmentModule.installation
            .workingTimeInMinutes,
        },
      },
    });
  }
}
