















import { Component, Prop, Vue } from "vue-property-decorator";
import type { AssignmentFile } from "@defa-as/utils";

@Component
export default class BaseFiles extends Vue {
  @Prop({ required: true })
  files!: AssignmentFile[];
}
