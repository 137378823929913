import {
  API_VERSION_FEBRUARY_2021,
  API_VERSION_JULY_2021,
  findRouteByName,
  Assignment,
  OrderWithHomecheckAndAssignment,
} from "@defa-as/utils";
import { IPVUser } from "@/store/model/user";
import { CancelToken } from "axios";
import { InstallationListResponse } from "@/types";
import { EventPayload, PatchAssignmentPayload } from "@/store/model/assignment";
import { PatchOrderPayload } from "@/store/model/order";
import { ROUTE_NAMES, routes } from "@/router";
import { httpClient } from "@/http/index";

const authorizationHeader = (secret?: string) =>
  secret
    ? {
        headers: {
          authorization: `Secret ${secret}`,
        },
      }
    : undefined;

export const getOrder = (orderId: string, secret?: string) => {
  return httpClient.get<
    OrderWithHomecheckAndAssignment,
    OrderWithHomecheckAndAssignment
  >(`/orders/${orderId}`, authorizationHeader(secret));
};
export const getInstallations = async (
  page: number,
  searchTerm: string,
  statuses: string[],
  cancelToken: CancelToken
): Promise<InstallationListResponse> => {
  return await httpClient.get<
    InstallationListResponse,
    InstallationListResponse
  >("/orders", {
    headers: {
      version: API_VERSION_FEBRUARY_2021,
      "X-Defa-Version": API_VERSION_FEBRUARY_2021,
    },
    params: {
      page,
      status: statuses,
      search: searchTerm,
      "context-app": "IPV",
      order: "dateCreated desc",
    },
    cancelToken,
  });
};

export const apiPatchAssignment = (
  eventPayload: PatchAssignmentPayload,
  { orderId, secret }: { orderId: string; secret: string }
) => {
  const body: EventPayload = {
    event: eventPayload.event,
  };
  if (eventPayload.payload) {
    body.payload = eventPayload.payload;
  }
  return httpClient.patch<Assignment, Assignment>(
    `/orders/${orderId}/assignment`,
    body,
    authorizationHeader(secret)
  );
};
export const apiPatchOrder = (
  eventPayload: PatchOrderPayload,
  { orderId, secret }: { orderId: string; secret: string }
) => {
  return httpClient.patch<
    OrderWithHomecheckAndAssignment,
    OrderWithHomecheckAndAssignment
  >(`/orders/${orderId}`, eventPayload, authorizationHeader(secret));
};
export const login = async (loginPayload: {
  loginName: string;
  password: string;
}) => {
  return httpClient.post<IPVUser, IPVUser>(
    "/users/login",
    {
      ...loginPayload,
      context: {
        app: "IPV",
      },
    },
    {
      headers: {
        version: API_VERSION_JULY_2021,
        "X-Defa-Version": API_VERSION_JULY_2021,
      },
    }
  );
};

export const changePassword = async (changePasswordPayload: {
  newPassword: string;
}) => {
  return httpClient.patch<IPVUser, IPVUser>(
    "/users/me",
    changePasswordPayload,
    {
      headers: {
        version: API_VERSION_JULY_2021,
        "X-Defa-Version": API_VERSION_JULY_2021,
      },
    }
  );
};

export const resetPassword = async ({
  newPassword,
  hash,
}: {
  newPassword: string;
  hash: string;
}) => {
  return await httpClient.post<IPVUser, IPVUser>(
    `/users/reset/change-password/${hash}`,
    {
      newPassword,
      context: {
        app: "IPV",
      },
    },
    {
      headers: {
        version: API_VERSION_JULY_2021,
        "X-Defa-Version": API_VERSION_JULY_2021,
      },
    }
  );
};

export const registerUser = async (registerPayload: {
  email: string;
  password: string;
}) => {
  return httpClient.post<IPVUser, IPVUser>(
    "/users",
    {
      ...registerPayload,
      confirmationUrl: `${window.location.origin}${
        findRouteByName(routes, ROUTE_NAMES.REGISTRATION.CONFIRMATION).path
      }`,
      context: {
        app: "IPV",
      },
    },
    {
      headers: {
        version: API_VERSION_JULY_2021,
        "X-Defa-Version": API_VERSION_JULY_2021,
      },
    }
  );
};

export const getUserInfo = async () =>
  httpClient.get<IPVUser, IPVUser>("/users/me", {
    headers: {
      version: API_VERSION_JULY_2021,
      "X-Defa-Version": API_VERSION_JULY_2021,
    },
  });

export const recoverPassword = async ({ email }: { email: string }) => {
  const recoverPasswordConfirmPath = findRouteByName(
    routes,
    ROUTE_NAMES.PASSWORD_RECOVERY.CONFIRMATION
  ).path;
  return httpClient.post<void, void>(
    "/users/reset/request",
    {
      context: {
        app: "IPV",
      },
      email,
      recoverUrl: `${window.location.origin}${recoverPasswordConfirmPath}`,
    },
    {
      headers: {
        version: API_VERSION_JULY_2021,
        "X-Defa-Version": API_VERSION_JULY_2021,
      },
    }
  );
};
