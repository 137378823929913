import type { AxiosAdapter } from "axios";
import axios from "axios";
import {
  applyRequestInterceptorsInOrder,
  defaultTimeout,
  getApiBaseUrl,
  requestInterceptorAddCustomHeaders,
  responseInterceptorExtractData,
  withAxiosRetry,
} from "@defa-as/utils";
import {
  requestInterceptorAddAuthorizationHeader,
  responseInterceptorReportError,
} from "@/http/interceptors";
import {
  cacheAdapterEnhancer,
  throttleAdapterEnhancer,
} from "axios-extensions";

export const httpClient = axios.create({
  baseURL: getApiBaseUrl(),
  timeout: defaultTimeout,
  headers: {
    "Cache-Control": "no-cache",
  },
  adapter: throttleAdapterEnhancer(
    cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
      enabledByDefault: false,
    })
  ),
});

withAxiosRetry(httpClient);

applyRequestInterceptorsInOrder({
  axiosInstance: httpClient,
  interceptors: [
    requestInterceptorAddAuthorizationHeader,
    requestInterceptorAddCustomHeaders,
  ],
});
httpClient.interceptors.response.use(
  responseInterceptorExtractData,
  responseInterceptorReportError
);
