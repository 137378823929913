





























































































































import { ValidationObserver } from "vee-validate";
import { Component, Mixins } from "vue-property-decorator";
import {
  DefaBaseButton,
  DefaBaseCheckbox,
  DefaBaseRadioGroup,
  DefaBaseTextarea,
  DefaModalAlert,
  DefaTransitionExpand,
} from "@defa-as/components";
import type { ConfirmationInstallationPossible } from "@defa-as/utils";
import StageGeneric from "@/components/stages/StageGeneric.vue";
import { StageId, TransitionStagePayload } from "@/store/model/progress";
import {
  optionsAdditionalServicesRequired,
  optionsCorrectWallbox,
  optionsInstallationPossible,
} from "@/config/options";
import { assignmentModule } from "@/store/modules/assignment-module";
import { orderModule } from "@/store/modules/order-module";
import { progressModule } from "@/store/modules/progress-module";
import ModalConfirmMixin from "@/mixins/modal-confirm.mixin";
import BaseModalConfirm from "@/components/common/base/BaseModalConfirm.vue";
import BaseReportSection from "@/components/common/base/BaseReportSection.vue";

@Component({
  components: {
    BaseReportSection,
    DefaModalAlert,
    BaseModalConfirm,
    ValidationObserver,
    DefaTransitionExpand,
    DefaBaseCheckbox,
    DefaBaseButton,
    DefaBaseTextarea,
    DefaBaseRadioGroup,
    StageGeneric,
  },
})
export default class StageAssignmentConfirmed extends Mixins(
  ModalConfirmMixin
) {
  readonly optionsCorrectWallbox = optionsCorrectWallbox();
  readonly optionsInstallationPossible = optionsInstallationPossible();
  readonly optionsAdditionalServicesRequired = optionsAdditionalServicesRequired();
  additionalServicesModalVisible = false;
  contactCustomerModalVisible = false;

  get comment() {
    return this.assignmentModule.confirmation.comment as string;
  }

  set comment(value: string) {
    this.assignmentModule.setConfirmationProperty({
      property: "comment",
      value,
    });
  }

  get rulesInstallationPossible() {
    return this.installationPossibleVisible ? "required" : null;
  }

  get rulesAdditionalServicesRequired() {
    return this.additionalServicesRequiredVisible ? "required" : null;
  }

  get rulesComment() {
    return this.commentVisible ? "required" : null;
  }

  get rulesCustomerContacted() {
    return {
      required: {
        allowFalse: false,
      },
    };
  }

  get customMessagesCustomerContacted() {
    return {
      required: this.$t("veeValidate.customMessages.confirmation"),
    };
  }

  get orderModule() {
    return orderModule;
  }

  get assignmentModule() {
    return assignmentModule;
  }

  get installationPossibleVisible() {
    return this.assignmentModule.confirmation.correctWallbox;
  }

  get additionalServicesRequiredVisible() {
    return (
      this.assignmentModule.confirmation.installationPossible === "yes" ||
      this.assignmentModule.confirmation.installationPossible ===
        "inspectionRequired"
    );
  }

  get commentVisible() {
    return (
      this.assignmentModule.confirmation.correctWallbox === false ||
      this.assignmentModule.confirmation.installationPossible === "no"
    );
  }

  get customerContacted() {
    return this.assignmentModule.local.customerContacted;
  }

  set customerContacted(value: boolean) {
    this.assignmentModule.setLocalProperty({
      property: "customerContacted",
      value,
    });
  }

  mounted() {
    this.contactCustomerModalVisible = true;
  }

  hideCustomerContactModal() {
    this.contactCustomerModalVisible = false;
  }

  hideAdditionalServicesModal() {
    this.additionalServicesModalVisible = false;
  }

  showAdditionalServicesModal() {
    this.additionalServicesModalVisible = true;
  }

  async onCorrectWallboxChange(value: boolean) {
    await this.assignmentModule.updateConfirmationCorrectWallbox({ value });
  }

  async onInstallationPossibleChange(value: ConfirmationInstallationPossible) {
    await this.assignmentModule.updateConfirmationInstallationPossible({
      value,
    });
  }

  async onAdditionalServicesRequiredChange(value: boolean) {
    if (value) {
      this.showAdditionalServicesModal();
    }
    await this.assignmentModule.updateConfirmationAdditionalServicesRequired({
      value,
    });
  }

  async nextStage() {
    this.hideModalConfirm();
    const transitionStagePayload: Omit<TransitionStagePayload, "stageId"> = {
      payload: {
        confirmation: {
          correctWallbox: this.assignmentModule.confirmation.correctWallbox,
          installationPossible: this.assignmentModule.confirmation
            .installationPossible,
          additionalServicesRequired: this.assignmentModule.confirmation
            .additionalServicesRequired,
          comment: this.assignmentModule.confirmation.comment,
        },
      },
    };
    if (
      this.assignmentModule.confirmation.installationPossible ===
      "inspectionRequired"
    ) {
      await progressModule.transitionStage({
        ...transitionStagePayload,
        stageId: StageId.INSPECTION_STARTED,
        event: "inspectionRequired",
      });
    } else {
      await progressModule.transitionStage({
        ...transitionStagePayload,
        stageId: StageId.CONSIGNMENT_STARTED,
        event: "inspectionSkipped",
      });
    }
  }
}
