







































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  DefaFullPageLoader,
  DefaProgressBar,
  DefaTransitionFadeOutIn,
} from "@defa-as/components";
import { onLocalOrTest } from "@defa-as/utils";
import CustomerInfo from "@/components/customer/sections/CustomerInfo.vue";
import CustomerInstallationAddress from "@/components/customer/sections/CustomerInstallationAddress.vue";
import CustomerHomecheck from "@/components/customer/sections/CustomerHomecheck.vue";
import CustomerChangelog from "@/components/customer/sections/CustomerChangelog.vue";
import SectionProducts from "@/components/products/sections/SectionProducts.vue";
import SectionTrackingInfo from "@/components/products/sections/SectionTrackingInfo.vue";
import StageAssignmentRejected from "@/components/stages/assignment/StageAssignmentRejected.vue";
import StageAssignmentOpened from "@/components/stages/assignment/StageAssignmentOpened.vue";
import StageAssignmentConfirmed from "@/components/stages/assignment/StageAssignmentConfirmed.vue";
import StageInspectionStarted from "@/components/stages/assignment/StageInspectionStarted.vue";
import StageInspectionCompleted from "@/components/stages/assignment/StageInspectionCompleted.vue";
import StageReportInspectionFailed from "@/components/stages/report/StageReportInspectionFailed.vue";
import StageConsignmentStarted from "@/components/stages/consigment/StageConsignmentStarted.vue";
import StageConsignmentSent from "@/components/stages/consigment/StageConsignmentSent.vue";
import StageInstallation from "@/components/stages/installation/StageInstallation.vue";
import StageReportInstallationFailed from "@/components/stages/report/StageReportInstallationFailed.vue";
import StageReportInstallationSuccessful from "@/components/stages/report/StageReportInstallationSuccessful.vue";
import StageReportCompleted from "@/components/stages/report/StageReportCompleted.vue";
import StageFailedRejected from "@/components/stages/failed/StageFailedRejected.vue";
import StageFailedInspection from "@/components/stages/failed/StageFailedInspection.vue";
import StageFailedInstallation from "@/components/stages/failed/StageFailedInstallation.vue";
import { progressModule } from "@/store/modules/progress-module";
import { loadingModule } from "@/store/modules/loading-module";
import { orderModule } from "@/store/modules/order-module";
import { userModule } from "@/store/modules/user-module";
import SectionComments from "@/components/comments/SectionComments.vue";

@Component({
  components: {
    SectionComments,
    DefaFullPageLoader,
    DefaTransitionFadeOutIn,
    DefaProgressBar,
    CustomerInfo,
    CustomerInstallationAddress,
    SectionProducts,
    SectionTrackingInfo,
    CustomerHomecheck,
    CustomerChangelog,
    StageAssignmentRejected,
    StageAssignmentOpened,
    StageAssignmentConfirmed,
    StageInspectionStarted,
    StageInspectionCompleted,
    StageReportInspectionFailed,
    StageConsignmentStarted,
    StageConsignmentSent,
    StageInstallation,
    StageReportInstallationFailed,
    StageReportInstallationSuccessful,
    StageReportCompleted,
    StageFailedRejected,
    StageFailedInspection,
    StageFailedInstallation,
  },
})
export default class ViewInstallation extends Vue {
  @Prop({ required: true }) readonly orderId!: string;
  @Prop({ default: "" }) readonly secret!: string;

  get progressItemsWithTranslatedLabels() {
    return progressModule.progressItems.map((progressItem) => ({
      ...progressItem,
      label: this.$t(progressItem.labelKey),
    }));
  }

  get userModule() {
    return userModule;
  }

  get orderModule() {
    return orderModule;
  }

  get loadingModule() {
    return loadingModule;
  }

  get progressModule() {
    return progressModule;
  }

  get onLocalOrTest() {
    return onLocalOrTest();
  }

  async created() {
    await this.orderModule.loadOrder({
      orderId: this.orderId,
      secret: this.secret,
    });
  }
}
