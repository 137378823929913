



















import { Component, Vue } from "vue-property-decorator";
import { DefaTransitionExpand } from "@defa-as/components";
import SvgPlusMin from "@/components/common/SvgPlusMin.vue";
import { trackOnTabletOrWider } from "@/utils";

@Component({
  components: {
    DefaTransitionExpand,
    SvgPlusMin,
  },
})
export default class SectionExpandable extends Vue {
  expanded = false;
  onTabletOrWider = false;

  toggleExpanded() {
    this.expanded = !this.expanded;
  }

  // This will track changes to the window size and control showing content, the expanded icon and whether clicking the section should call any methods
  created() {
    trackOnTabletOrWider((onTabletOrWider) => {
      this.expanded = onTabletOrWider;
      this.onTabletOrWider = onTabletOrWider;
    });
  }
}
