import {
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import type { Homecheck } from "@defa-as/utils";
import store from "@/store";

@Module({ name: "homecheck", namespaced: true, dynamic: true, store })
export class HomecheckModule extends VuexModule {
  data: Homecheck = {
    propertyId: null,
    personalIdentificationNumber: null,
    propertyType: null,
    propertyOwnerApproval: null,
    mainFuseCapacity: null,
    voltage: null,
    distributionBoardSpaceAvailable: null,
    cableLengthEstimate: null,
    internetConnection: null,
    excavationNecessary: null,
    specializedDrillingNecessary: null,
    comment: null,
    distributionBoardImages: [],
    distributionBoardSchemaImages: [],
    electricityMeterBoxImages: [],
    installationSiteImages: [],
  };

  get images() {
    return [
      ...this.data.distributionBoardImages,
      ...this.data.distributionBoardSchemaImages,
      ...this.data.electricityMeterBoxImages,
      ...this.data.installationSiteImages,
    ];
  }

  @Mutation
  setHomecheckData({ data }: { data: Homecheck }) {
    this.data = data;
  }
}

export const homecheckModule = getModule(HomecheckModule);
