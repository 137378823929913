import "@/class-component";
import Vue from "vue";
import { setupValidation } from "@/validation";
import {
  configureSentry,
  formatDate,
  formatDatetimeShort,
  onLocalOrTest,
  setLangOnHtmlElement,
  setupThemingOnRootElement,
  RELEASE_VERSION,
} from "@defa-as/utils";

export const setup = () => {
  if (onLocalOrTest()) {
    Vue.config.devtools = true;
  }

  setupValidation();
  setupThemingOnRootElement();
  setLangOnHtmlElement();
  configureSentry(Vue, RELEASE_VERSION);

  Vue.filter("formatDatetimeShort", formatDatetimeShort);
  Vue.filter("formatDate", formatDate);
};
