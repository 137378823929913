






































import { Component, Vue } from "vue-property-decorator";
import StageGeneric from "@/components/stages/StageGeneric.vue";
import StageFailedHeader from "@/components/stages/failed/StageFailedHeader.vue";
import { assignmentModule } from "@/store/modules/assignment-module";
import BaseReportSection from "@/components/common/base/BaseReportSection.vue";

@Component({
  components: {
    BaseReportSection,
    StageFailedHeader,
    StageGeneric,
  },
})
export default class StageFailedRejected extends Vue {
  get assignmentModule() {
    return assignmentModule;
  }
}
