export const trackOnTabletOrWider = (
  listener: (onTabletOrWider: boolean) => void
) => {
  const onTablerOrWiderMQL = window.matchMedia("(min-width: 992px)");
  if (onTablerOrWiderMQL.addEventListener) {
    onTablerOrWiderMQL.addEventListener(
      "change",
      (mql) => listener(mql.matches),
      { passive: true }
    );
  } else {
    // Fix for Safari
    onTablerOrWiderMQL.addListener((mql) => listener(mql.matches));
  }
  listener(onTablerOrWiderMQL.matches);
};
