














import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaBaseInput } from "@defa-as/components";
import type { OrderLine } from "@defa-as/utils";

@Component({
  components: {
    DefaBaseInput,
  },
  model: {
    prop: "modelValue",
  },
})
export default class StageReportOrderLine extends Vue {
  @Prop({ required: true }) readonly orderLine!: OrderLine;
  @Prop({ required: true }) readonly modelValue!: string;

  get modelOrMissingModelText() {
    return (
      this.orderLine.model ||
      this.$t("stageReportOrderLine.model.missing", {
        sku: this.orderLine.sku,
      })
    );
  }

  get name() {
    return `serialNumber-${this.orderLine.id}`;
  }

  get value() {
    return this.modelValue;
  }

  set value(newValue: string) {
    this.$emit("input", newValue);
  }
}
