










































import { ValidationObserver } from "vee-validate";
import { Component, Mixins } from "vue-property-decorator";
import { DefaBaseButton, DefaBaseDatetimeInput } from "@defa-as/components";
import StageGeneric from "@/components/stages/StageGeneric.vue";
import InstallationScheduledMixin from "@/mixins/installation-scheduled.mixin";
import { StageId } from "@/store/model/progress";
import ModalConfirmMixin from "@/mixins/modal-confirm.mixin";
import { progressModule } from "@/store/modules/progress-module";
import BaseModalConfirm from "@/components/common/base/BaseModalConfirm.vue";

@Component({
  components: {
    BaseModalConfirm,
    ValidationObserver,
    StageGeneric,
    DefaBaseDatetimeInput,
    DefaBaseButton,
  },
})
export default class StageInstallation extends Mixins(
  InstallationScheduledMixin,
  ModalConfirmMixin
) {
  onConfirm() {
    this.modalConfirmVisible = false;
    if (this.confirmAction) {
      this.confirmAction();
    }
  }

  openConfirmCompleteInstallation() {
    this.showModalConfirm(this.completeInstallation);
  }

  openConfirmFailInstallation() {
    this.showModalConfirm(this.failInstallation);
  }

  async completeInstallation() {
    await progressModule.transitionStage({
      stageId: StageId.REPORT_INSTALLATION_SUCCESSFUL,
      event: "installationSuccessful",
    });
  }

  async failInstallation() {
    await progressModule.transitionStage({
      stageId: StageId.REPORT_INSTALLATION_FAILED,
      event: "installationFailed",
    });
  }
}
