import {
  ConfirmationInstallationPossible,
  InspectionReasonFailed,
  InstallationReasonFailed,
  Option,
  RejectionReason,
} from "@defa-as/utils";
import i18n from "@/i18n";

const getOptions = <T extends Option["value"]>(
  values: T[],
  optionName: string
) => {
  return values.map((value) => {
    return {
      value,
      label: i18n.t(`stages.options.${optionName}.label.${value}`),
    };
  });
};

export const optionsInstallationReasonFailed = () =>
  getOptions<InstallationReasonFailed>(
    ["installationNotPossible", "installationPostponed", "other"],
    "installationReasonFailed"
  );

export const optionsInspectionReasonFailed = () =>
  getOptions<InspectionReasonFailed>(
    ["notFeasible", "tooExpensive", "canceled", "other"],
    "inspectionReasonFailed"
  );

export const optionsRejectionReason = () =>
  getOptions<RejectionReason>(
    ["noCapacity", "outsideServiceArea", "other"],
    "rejectionReason"
  );

export const optionsInstallationPossible = () =>
  getOptions<ConfirmationInstallationPossible>(
    ["yes", "no", "inspectionRequired"],
    "installationPossible"
  );

export const optionsCorrectWallbox = () =>
  getOptions([true, false], "correctWallbox");

export const optionsAdditionalServicesRequired = () =>
  getOptions([true, false], "additionalServicesRequired");
