import Vue from "vue";
import Vuex from "vuex";
import { config } from "vuex-module-decorators";
import {
  clearLocalStorageIfOnNewVersion,
  getVuexLocalStorageKey,
  onLocal,
} from "@defa-as/utils";
import VuexPersistence from "vuex-persist";
import type { UserModule } from "@/store/modules/user-module";
import type { ProgressModule } from "@/store/modules/progress-module";
import type { OrderModule } from "@/store/modules/order-module";
import type { LoadingModule } from "@/store/modules/loading-module";
import type { HomecheckModule } from "@/store/modules/homecheck-module";
import type { ErrorModule } from "@/store/modules/error-module";
import type { AssignmentModule } from "@/store/modules/assignment-module";

Vue.use(Vuex);

config.rawError = true;

const plugins = [];

interface StoreWithModules {
  user: UserModule;
  progress: ProgressModule;
  order: OrderModule;
  loading: LoadingModule;
  homecheck: HomecheckModule;
  error: ErrorModule;
  assignment: AssignmentModule;
}

const localStorageKey = getVuexLocalStorageKey("vuex-installation", true);
clearLocalStorageIfOnNewVersion(localStorageKey);
const vuexLocalStorage = new VuexPersistence<StoreWithModules>({
  key: localStorageKey,
  storage: window.localStorage,
  modules: ["user"],
});
plugins.push(vuexLocalStorage.plugin);

export default new Vuex.Store<StoreWithModules>({
  strict: onLocal(),
  plugins,
});
