







import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { gsap } from "gsap";

@Component
export default class SvgPlusMin extends Vue {
  @Prop({ required: true }) readonly isMinus!: boolean;
  @Prop({ default: 0.3 }) readonly animationDuration!: number;
  @Ref() readonly yLine!: SVGLineElement;

  @Watch("isMinus")
  onMinusChanged(isMinus: boolean) {
    gsap.set(this.yLine, { transformOrigin: "50% 50%" });
    if (isMinus) {
      gsap.to(this.yLine, {
        scaleY: 0,
        duration: this.animationDuration,
      });
    } else {
      gsap.to(this.yLine, {
        scaleY: 1,
        duration: this.animationDuration,
      });
    }
  }
}
