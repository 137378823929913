




























































import { Component, Vue } from "vue-property-decorator";
import {
  DefaBaseOrderLine,
  DefaTransitionFadeOutIn,
  DefaTransitionStaggerList,
} from "@defa-as/components";
import SectionExpandable from "@/components/common/SectionExpandable.vue";
import { orderModule } from "@/store/modules/order-module";
import { assignmentModule } from "@/store/modules/assignment-module";

@Component({
  components: {
    SectionExpandable,
    DefaTransitionFadeOutIn,
    DefaTransitionStaggerList,
    DefaBaseOrderLine,
  },
})
export default class SectionProducts extends Vue {
  showAll = false;

  get orderModule() {
    return orderModule;
  }

  get assignmentModule() {
    return assignmentModule;
  }

  get orderLinesSortedByTypeAndName() {
    return [...this.orderModule.orderLines].sort(
      (orderLineOne, orderLineTwo) => {
        if (orderLineOne.productType === orderLineTwo.productType) {
          return orderLineOne.name.localeCompare(orderLineTwo.name);
        }
        if (orderLineOne.productType === "chargingStation") {
          return -1;
        }
        if (orderLineTwo.productType === "chargingStation") {
          return 1;
        }
        return orderLineOne.name.localeCompare(orderLineTwo.name);
      }
    );
  }

  get firstOrderLine() {
    if (this.orderLinesSortedByTypeAndName.length) {
      return this.orderLinesSortedByTypeAndName[0];
    }
    return null;
  }

  get remainingOrderLines() {
    return this.orderLinesSortedByTypeAndName.slice(1);
  }

  get linkShowAllVisible() {
    return this.orderLinesSortedByTypeAndName.length > 1;
  }

  get firstOrderLineWithName() {
    return (index: number, name: string) => {
      const previousOrderLine = this.orderLinesSortedByTypeAndName[index - 1];
      if (previousOrderLine) {
        return previousOrderLine.name !== name;
      }
      return true;
    };
  }

  toggleShowAll() {
    this.showAll = !this.showAll;
  }
}
