





















import { Component, Vue } from "vue-property-decorator";
import { DefaIconMapPointer } from "@defa-as/components";
import SectionExpandable from "@/components/common/SectionExpandable.vue";
import { orderModule } from "@/store/modules/order-module";

@Component({
  components: {
    SectionExpandable,
    DefaIconMapPointer,
  },
})
export default class CustomerInstallationAddress extends Vue {
  get orderModule() {
    return orderModule;
  }

  get googleMapsLink() {
    return encodeURI(
      `https://www.google.com/maps/search/?api=1&query=${this.orderModule.customerAddressLine1}+${this.orderModule.customerPostcodeAndPlace}`
    );
  }
}
