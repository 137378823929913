









import { Component, Vue } from "vue-property-decorator";
import StageFailedHeaderIcon from "@/components/stages/failed/StageFailedHeaderIcon.vue";
import { orderModule } from "@/store/modules/order-module";

@Component({
  components: { StageFailedHeaderIcon },
})
export default class StageReportCompletedHeader extends Vue {
  get orderModule() {
    return orderModule;
  }
}
