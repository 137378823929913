import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import type { AssignmentTimestamps } from "@defa-as/utils";
import store from "@/store";
import type {
  ProgressItem,
  Stage,
  Stages,
  TransitionStagePayload,
} from "@/store/model/progress";
import {
  isPatchAssignmentPayload,
  ProgressItemId,
  StageId,
} from "@/store/model/progress";
import { getStartFromStage } from "@/config";
import { loadingModule } from "@/store/modules/loading-module";
import { assignmentModule } from "@/store/modules/assignment-module";
import router from "@/router";
import { apiPatchAssignment } from "@/http/requests";

@Module({ name: "progress", namespaced: true, dynamic: true, store })
export class ProgressModule extends VuexModule {
  readonly stages: Stages = {
    [StageId.ASSIGNMENT_REJECTED]: {
      componentName: "stage-assignment-rejected",
      activeProgressItemIds: [ProgressItemId.CONFIRMED],
    },
    [StageId.ASSIGNMENT_OPENED]: {
      componentName: "stage-assignment-opened",
      activeProgressItemIds: [ProgressItemId.CONFIRMED],
    },
    [StageId.ASSIGNMENT_CONFIRMED]: {
      componentName: "stage-assignment-confirmed",
      activeProgressItemIds: [ProgressItemId.CONFIRMED],
    },
    [StageId.INSPECTION_STARTED]: {
      componentName: "stage-inspection-started",
      activeProgressItemIds: [ProgressItemId.CONFIRMED],
    },
    [StageId.INSPECTION_COMPLETED]: {
      componentName: "stage-inspection-completed",
      activeProgressItemIds: [ProgressItemId.CONFIRMED],
    },
    [StageId.REPORT_INSPECTION_FAILED]: {
      componentName: "stage-report-inspection-failed",
      activeProgressItemIds: [ProgressItemId.CONFIRMED],
    },
    [StageId.CONSIGNMENT_STARTED]: {
      componentName: "stage-consignment-started",
      activeProgressItemIds: [
        ProgressItemId.CONFIRMED,
        ProgressItemId.SHIPPING,
      ],
    },
    [StageId.CONSIGNMENT_SENT]: {
      componentName: "stage-consignment-sent",
      activeProgressItemIds: [
        ProgressItemId.CONFIRMED,
        ProgressItemId.SHIPPING,
      ],
    },
    [StageId.INSTALLATION]: {
      componentName: "stage-installation",
      activeProgressItemIds: [
        ProgressItemId.CONFIRMED,
        ProgressItemId.SHIPPING,
        ProgressItemId.INSTALLATION,
      ],
    },
    [StageId.REPORT_INSTALLATION_SUCCESSFUL]: {
      componentName: "stage-report-installation-successful",
      activeProgressItemIds: [
        ProgressItemId.CONFIRMED,
        ProgressItemId.SHIPPING,
        ProgressItemId.INSTALLATION,
        ProgressItemId.REPORT,
      ],
    },
    [StageId.REPORT_INSTALLATION_FAILED]: {
      componentName: "stage-report-installation-failed",
      activeProgressItemIds: [
        ProgressItemId.CONFIRMED,
        ProgressItemId.SHIPPING,
        ProgressItemId.INSTALLATION,
        ProgressItemId.REPORT,
      ],
    },
    [StageId.REPORT_COMPLETED]: {
      componentName: "stage-report-completed",
      activeProgressItemIds: [
        ProgressItemId.CONFIRMED,
        ProgressItemId.SHIPPING,
        ProgressItemId.INSTALLATION,
        ProgressItemId.REPORT,
      ],
    },
    [StageId.FAILED_REJECTED]: {
      componentName: "stage-failed-rejected",
      activeProgressItemIds: [],
    },
    [StageId.FAILED_INSPECTION]: {
      componentName: "stage-failed-inspection",
      activeProgressItemIds: [],
    },
    [StageId.FAILED_INSTALLATION]: {
      componentName: "stage-failed-installation",
      activeProgressItemIds: [],
    },
  };

  readonly progressItems: ProgressItem[] = [
    {
      id: ProgressItemId.CONFIRMED,
      labelKey: "progressItems.confirm",
      active: true,
    },
    {
      id: ProgressItemId.SHIPPING,
      labelKey: "progressItems.shipping",
      active: false,
    },
    {
      id: ProgressItemId.INSTALLATION,
      labelKey: "progressItems.installation",
      active: false,
    },
    {
      id: ProgressItemId.REPORT,
      labelKey: "progressItems.report",
      active: false,
    },
  ];

  currentStage = this.stages[StageId.ASSIGNMENT_OPENED];

  get currentStageReportCompleted() {
    return this.currentStage === this.stages[StageId.REPORT_COMPLETED];
  }

  get progressItemById() {
    return (id: ProgressItemId) => {
      const progressItem = this.progressItems.find(
        (progressItem) => progressItem.id === id
      );
      if (progressItem) {
        return progressItem;
      }
      throw new Error(`Progress label with id ${id} does not exist!`);
    };
  }

  get activeProgressItemsIds() {
    return this.progressItems
      .filter((progressItem) => progressItem.active)
      .map((progressItem) => progressItem.id);
  }

  get hasActiveProgressItemIds() {
    return Boolean(this.activeProgressItemsIds.length);
  }

  get progressItemsToBeActivated() {
    return this.currentStage.activeProgressItemIds
      .filter(
        (activeProgressItemId) =>
          !this.activeProgressItemsIds.includes(activeProgressItemId)
      )
      .map((progressItemId) => this.progressItemById(progressItemId));
  }

  get progressItemsToBeDeactivated() {
    return this.activeProgressItemsIds
      .filter(
        (activeProgressItemId) =>
          !this.currentStage.activeProgressItemIds.includes(
            activeProgressItemId
          )
      )
      .map((progressItemId) => this.progressItemById(progressItemId));
  }

  @Mutation
  setCurrentStage({ stage }: { stage: Stage }) {
    this.currentStage = stage;
  }

  @Mutation
  setProgressItemActive(progressItem: ProgressItem) {
    progressItem.active = true;
  }

  @Mutation
  setProgressItemInactive(progressItem: ProgressItem) {
    progressItem.active = false;
  }

  @Action
  async initializeStage({ timestamps }: { timestamps: AssignmentTimestamps }) {
    const startFromStage = getStartFromStage();
    if (startFromStage) {
      this.setCurrentStage({
        stage: this.stages[StageId[startFromStage as keyof typeof StageId]],
      });
    } else if (timestamps.assignmentRejected) {
      this.setCurrentStage({ stage: this.stages[StageId.FAILED_REJECTED] });
    } else if (
      timestamps.reportCompleted &&
      timestamps.installationSuccessful
    ) {
      this.setCurrentStage({ stage: this.stages[StageId.REPORT_COMPLETED] });
    } else if (timestamps.reportCompleted && timestamps.installationFailed) {
      this.setCurrentStage({ stage: this.stages[StageId.FAILED_INSTALLATION] });
    } else if (timestamps.installationSuccessful) {
      this.setCurrentStage({
        stage: this.stages[StageId.REPORT_INSTALLATION_SUCCESSFUL],
      });
    } else if (timestamps.installationFailed) {
      this.setCurrentStage({
        stage: this.stages[StageId.REPORT_INSTALLATION_FAILED],
      });
    } else if (timestamps.consignmentCompleted) {
      this.setCurrentStage({ stage: this.stages[StageId.INSTALLATION] });
    } else if (timestamps.consignmentRequested || timestamps.consignmentSent) {
      this.setCurrentStage({ stage: this.stages[StageId.CONSIGNMENT_SENT] });
    } else if (
      timestamps.inspectionSuccessful ||
      timestamps.inspectionSkipped
    ) {
      this.setCurrentStage({ stage: this.stages[StageId.CONSIGNMENT_STARTED] });
    } else if (timestamps.inspectionFailed) {
      this.setCurrentStage({ stage: this.stages[StageId.FAILED_INSPECTION] });
    } else if (timestamps.inspectionRequired) {
      this.setCurrentStage({ stage: this.stages[StageId.INSPECTION_STARTED] });
    } else if (timestamps.assignmentConfirmed) {
      this.setCurrentStage({
        stage: this.stages[StageId.ASSIGNMENT_CONFIRMED],
      });
    } else {
      this.setCurrentStage({ stage: this.stages[StageId.ASSIGNMENT_OPENED] });
    }
    await this.syncProgressItems();
  }

  @Action
  async transitionStage(payload: TransitionStagePayload) {
    loadingModule.setUpdateLoading();
    try {
      // Some stage transitions can be done without sending backend events
      if (isPatchAssignmentPayload(payload)) {
        const assignment = await apiPatchAssignment(payload, {
          orderId: router.currentRoute.params.orderId,
          secret: router.currentRoute.params.secret,
        });
        assignmentModule.setAssignmentData({ data: assignment });
      }
      this.setCurrentStage({ stage: this.stages[payload.stageId] });
      await this.syncProgressItems();
    } finally {
      loadingModule.unsetUpdateLoading();
    }
  }

  @Action
  async syncProgressItems() {
    this.progressItemsToBeActivated.forEach((activeProgressItem) => {
      this.setProgressItemActive(activeProgressItem);
    });
    this.progressItemsToBeDeactivated.forEach((inactiveProgressItem) => {
      this.setProgressItemInactive(inactiveProgressItem);
    });
  }
}

export const progressModule = getModule(ProgressModule);
