import { configure, extend } from "vee-validate";
import {
  confirmed,
  email,
  min_value as minValue,
  required,
} from "vee-validate/dist/rules";
import vueI18n from "@/i18n";
import {
  defaultMessageConfigurationFn,
  getPasswordRule,
  getPostalCodeRule,
} from "@defa-as/utils";

export const setupValidation = () => {
  registerValidationRules();
  configureValidationMessages();
};

const configureValidationMessages = () => {
  configure({
    defaultMessage: defaultMessageConfigurationFn(vueI18n),
    useConstraintAttrs: false,
  });
};

const registerValidationRules = () => {
  extend("required", required);
  extend("email", email);
  extend("confirmed", confirmed);
  extend("min_value", minValue);
  extend("postalCode", getPostalCodeRule());
  extend("password", getPasswordRule());
};
