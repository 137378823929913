















































import { Component, Vue } from "vue-property-decorator";
import {
  DefaIconCalendar,
  DefaIconCar,
  DefaIconEnvelope,
  DefaIconId,
  DefaIconPhone,
} from "@defa-as/components";
import SectionExpandable from "@/components/common/SectionExpandable.vue";
import { orderModule } from "@/store/modules/order-module";

@Component({
  components: {
    SectionExpandable,
    DefaIconCalendar,
    DefaIconCar,
    DefaIconId,
    DefaIconEnvelope,
    DefaIconPhone,
  },
})
export default class CustomerInfo extends Vue {
  get orderModule() {
    return orderModule;
  }

  get mailtoCustomer() {
    return `mailto:${orderModule.customerEmail}`;
  }

  get mailToPhone() {
    return `mailto:${orderModule.customerPhone}`;
  }
}
