



















import { Component, Vue } from "vue-property-decorator";
import StageReportOrderLine from "@/components/stages/report/StageReportOrderLine.vue";
import { assignmentModule } from "@/store/modules/assignment-module";
import type { UpdateSerialNumberPayload } from "@/store/model/assignment";
import { orderModule } from "@/store/modules/order-module";

@Component({
  components: {
    StageReportOrderLine,
  },
})
export default class StageReportOrderLines extends Vue {
  get orderModule() {
    return orderModule;
  }

  get assignmentModule() {
    return assignmentModule;
  }

  onUpdateSerialNumber(payload: UpdateSerialNumberPayload) {
    this.assignmentModule.updateInstallationSerialNumber(payload);
  }
}
