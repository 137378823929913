import {
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";

@Module({ name: "loading", dynamic: true, store, namespaced: true })
export class LoadingModule extends VuexModule {
  updateLoading = false;
  initializationLoading = false;

  @Mutation
  setUpdateLoading() {
    this.updateLoading = true;
  }

  @Mutation
  unsetUpdateLoading() {
    this.updateLoading = false;
  }

  @Mutation
  setInitializationLoading() {
    this.initializationLoading = true;
  }

  @Mutation
  unsetInitializationLoading() {
    this.initializationLoading = false;
  }
}

export const loadingModule = getModule(LoadingModule);
