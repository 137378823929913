























































import { Component, Vue } from "vue-property-decorator";
import { DefaBlurUpImage, DefaImageCarousel } from "@defa-as/components";
import type { HomecheckImage, Nullable } from "@defa-as/utils";
import SectionExpandable from "@/components/common/SectionExpandable.vue";
import { homecheckModule } from "@/store/modules/homecheck-module";
import { orderModule } from "@/store/modules/order-module";

@Component({
  components: {
    DefaImageCarousel,
    DefaBlurUpImage,
    SectionExpandable,
  },
})
export default class CustomerHomecheck extends Vue {
  image: Nullable<HomecheckImage> = null;

  get optionText() {
    return (value: string) => this.$t(`shared.homecheckOption.value.${value}`);
  }

  get orderModule() {
    return orderModule;
  }

  get homecheckModule() {
    return homecheckModule;
  }

  get relevantAnswers() {
    return Object.entries(this.homecheckModule.data)
      .filter((entry) => !!entry[1])
      .filter((entry) => !Array.isArray(entry[1])) // Filter out images
      .map(([key, answer]) => ({
        i18nKey: key,
        labelKey: `${key}-${answer}-label`,
        answerKey: `${key}-${answer}-answer`,
        isOption: ![
          "comment",
          "propertyId",
          "personalIdentificationNumber",
        ].includes(key),
        value: answer as string,
      }));
  }

  onClickImage({ image }: { image: HomecheckImage }) {
    this.image = image;
  }

  onImageCarouselHide() {
    this.image = null;
  }
}
