import type {
  EventPayload,
  PatchAssignmentPayload,
} from "@/store/model/assignment";

export enum StageId {
  ASSIGNMENT_REJECTED,
  ASSIGNMENT_OPENED,
  ASSIGNMENT_CONFIRMED,
  INSPECTION_STARTED,
  INSPECTION_COMPLETED,
  REPORT_INSPECTION_FAILED,
  CONSIGNMENT_STARTED,
  CONSIGNMENT_SENT,
  INSTALLATION,
  REPORT_INSTALLATION_SUCCESSFUL,
  REPORT_INSTALLATION_FAILED,
  REPORT_COMPLETED,
  FAILED_REJECTED,
  FAILED_INSPECTION,
  FAILED_INSTALLATION,
}

export const enum ProgressItemId {
  CONFIRMED,
  SHIPPING,
  INSTALLATION,
  REPORT,
}

export interface Stage {
  componentName: string;
  activeProgressItemIds: ProgressItemId[];
}

export type Stages = {
  [stageId in StageId]: Stage;
};

export interface ProgressItem {
  id: ProgressItemId;
  labelKey: string;
  active: boolean;
}

export interface TransitionStagePayload extends Partial<EventPayload> {
  stageId: StageId;
}

export const isPatchAssignmentPayload = (
  payload: Partial<TransitionStagePayload>
): payload is PatchAssignmentPayload => Boolean(payload.event);
