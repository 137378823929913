


































import { Component, Mixins } from "vue-property-decorator";
import {
  DefaBaseButton,
  DefaBaseDatetimeInput,
  DefaTrackingInformation,
} from "@defa-as/components";
import StageGeneric from "@/components/stages/StageGeneric.vue";
import InstallationScheduledMixin from "@/mixins/installation-scheduled.mixin";
import { StageId } from "@/store/model/progress";
import ModalConfirmMixin from "@//mixins/modal-confirm.mixin";
import { progressModule } from "@/store/modules/progress-module";
import { orderModule } from "@/store/modules/order-module";
import BaseModalConfirm from "@/components/common/base/BaseModalConfirm.vue";

@Component({
  components: {
    BaseModalConfirm,
    StageGeneric,
    DefaBaseDatetimeInput,
    DefaTrackingInformation,
    DefaBaseButton,
  },
})
export default class StageConsignmentSent extends Mixins(
  InstallationScheduledMixin,
  ModalConfirmMixin
) {
  get orderModule() {
    return orderModule;
  }

  async completeConsignmentSent() {
    this.hideModalConfirm();
    await progressModule.transitionStage({
      stageId: StageId.INSTALLATION,
      event: "consignmentCompleted",
    });
  }
}
