






















import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaBaseButton, DefaModal } from "@defa-as/components";

@Component({
  components: { DefaModal, DefaBaseButton },
})
export default class BaseModalConfirm extends Vue {
  @Prop({ required: true }) readonly show!: boolean;
}
