































import { Component, Vue } from "vue-property-decorator";
import {
  DefaIconList,
  DefaIconUser,
  DefaRetailerGroupLogo,
} from "@defa-as/components";
import { userModule } from "@/store/modules/user-module";
import { ROUTE_NAMES } from "@/router";

@Component({
  components: {
    DefaIconUser,
    DefaIconList,
    DefaRetailerGroupLogo,
  },
})
export default class TheHeader extends Vue {
  get loginRoute() {
    return {
      name: ROUTE_NAMES.LOGIN,
    };
  }

  get userProfileRoute() {
    return {
      name: ROUTE_NAMES.PROFILE,
    };
  }

  get installationListRoute() {
    return {
      name: ROUTE_NAMES.INSTALLATION.LIST,
    };
  }

  get userModule() {
    return userModule;
  }

  get isInstallationsLinkVisible() {
    return this.$route.name !== ROUTE_NAMES.INSTALLATION.LIST;
  }

  get isUserProfileRouteVisible() {
    return this.$route.name !== ROUTE_NAMES.PROFILE;
  }
}
