































import { Component, Mixins } from "vue-property-decorator";
import { formatDatetimeShort } from "@defa-as/utils";
import { DefaBaseButton, DefaBaseDatetimeInput } from "@defa-as/components";
import StageGeneric from "@/components/stages/StageGeneric.vue";
import { StageId } from "@/store/model/progress";
import { assignmentModule } from "@/store/modules/assignment-module";
import { progressModule } from "@/store/modules/progress-module";
import ModalConfirmMixin from "@/mixins/modal-confirm.mixin";
import BaseModalConfirm from "@/components/common/base/BaseModalConfirm.vue";

@Component({
  components: {
    BaseModalConfirm,
    StageGeneric,
    DefaBaseDatetimeInput,
    DefaBaseButton,
  },
})
export default class StageInspectionStarted extends Mixins(ModalConfirmMixin) {
  get assignmentModule() {
    return assignmentModule;
  }

  get inspectionScheduledPlaceholder() {
    if (this.assignmentModule.inspectionScheduledDate) {
      return this.$t("stages.common.inspectionScheduled", {
        datetime: formatDatetimeShort(
          this.assignmentModule.inspectionScheduledDate
        ),
      });
    }
    return this.$t("stages.common.toScheduleInspection");
  }

  onConfirm() {
    this.modalConfirmVisible = false;
    if (this.confirmAction) {
      this.confirmAction();
    }
  }

  openConfirmComplete() {
    this.showModalConfirm(this.completeInspection);
  }

  openConfirmSkip() {
    this.showModalConfirm(this.skipInspection);
  }

  async onChange(value: Date) {
    await assignmentModule.patchAssignment({
      event: "inspectionScheduled",
      payload: {
        inspection: {
          scheduled: value,
        },
      },
    });
  }

  async skipInspection() {
    await progressModule.transitionStage({
      stageId: StageId.CONSIGNMENT_STARTED,
      event: "inspectionSkipped",
    });
  }

  async completeInspection() {
    await progressModule.transitionStage({
      stageId: StageId.INSPECTION_COMPLETED,
      event: "inspectionCompleted",
    });
  }
}
