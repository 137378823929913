






















import { Component, Vue } from "vue-property-decorator";
import { orderModule } from "@/store/modules/order-module";
import { trackOnTabletOrWider } from "@/utils";
import { Nullable } from "@defa-as/utils";

@Component
export default class StageGeneric extends Vue {
  onTabletOrWider: Nullable<boolean> = null;

  get orderModule() {
    return orderModule;
  }

  get hasParagraphSlot() {
    return !!this.$slots.paragraph;
  }

  get hasReportSlot() {
    return !!this.$slots.report;
  }

  get hasActionsSlot() {
    return !!this.$slots.actions;
  }

  created() {
    trackOnTabletOrWider((onTabletOrWider) => {
      this.onTabletOrWider = onTabletOrWider;
    });
  }
}
