import { Component, Vue } from "vue-property-decorator";
import { formatDatetimeShort } from "@defa-as/utils";
import { assignmentModule } from "@/store/modules/assignment-module";

@Component
export default class InstallationScheduledMixin extends Vue {
  get assignmentModule() {
    return assignmentModule;
  }

  get installationScheduledPlaceholder() {
    if (this.assignmentModule.installationScheduledDate) {
      return this.$t("stages.common.installationScheduled", {
        datetime: formatDatetimeShort(
          this.assignmentModule.installationScheduledDate
        ),
      });
    }
    return this.$t("stages.common.toScheduleInstallation");
  }

  async onChange(value: Date) {
    await assignmentModule.patchAssignment({
      event: "installationScheduled",
      payload: {
        installation: {
          scheduled: value,
        },
      },
    });
  }
}
