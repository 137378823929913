























import { Component, Vue } from "vue-property-decorator";
import VueRecaptcha from "vue-recaptcha";
import {
  DefaComments,
  DefaTransitionFadeOutIn,
  DefaFullPageLoader,
} from "@defa-as/components";
import {
  addComment,
  getCommentsForOrder,
  markCommentAsRead,
} from "@defa-as/utils";
import type { Comment } from "@defa-as/utils";
import SectionExpandable from "@/components/common/SectionExpandable.vue";
import { userModule } from "@/store/modules/user-module";
import { httpClient } from "@/http";
import { orderModule } from "@/store/modules/order-module";

@Component({
  components: {
    SectionExpandable,
    DefaFullPageLoader,
    DefaComments,
    DefaTransitionFadeOutIn,
    VueRecaptcha,
  },
})
export default class SectionComments extends Vue {
  comments: Comment[] = [];
  loading = {
    getComments: false,
    addComment: false,
  };

  async created() {
    this.loading.getComments = true;
    try {
      this.comments = await getCommentsForOrder(
        httpClient,
        orderModule.id,
        orderModule.secret
      );
    } finally {
      this.loading.getComments = false;
    }
  }

  get recaptchaSiteKey() {
    if (userModule.isLoggedIn) {
      return null;
    }
    return process.env.VUE_APP_RECAPTCHA_SITE_KEY;
  }

  get commentAnchorToScrollTo() {
    return this.$route.hash;
  }

  async onSend(chatMessage: string) {
    this.loading.addComment = true;
    try {
      const commentNew = await addComment(
        httpClient,
        orderModule.id,
        {
          content: chatMessage,
        },
        orderModule.secret
      );
      this.comments = [...this.comments, commentNew];
    } finally {
      this.loading.addComment = false;
    }
  }

  async onRead({ comment }: { comment: Comment }) {
    const commentUpdated = await markCommentAsRead(
      httpClient,
      orderModule.id,
      comment,
      orderModule.secret
    );
    const mergeArrayWithObject = (arr: Comment[], obj: Comment) =>
      arr && arr.map((t: Comment) => (t.id === obj.id ? obj : t));
    this.comments = mergeArrayWithObject(this.comments, commentUpdated);
  }
}
