import { getApiBaseUrl, onLocalOrTest } from "@defa-as/utils";

export const fileUploadUrl = (orderId: string) =>
  `${getApiBaseUrl()}/orders/${orderId}/files`;

export const getStartFromStage = () => {
  if (onLocalOrTest()) {
    return process.env.VUE_APP_START_FROM_STAGE as string;
  }
  return null;
};
