import { VueRouter } from "vue-router/types/router";
import { userModule } from "@/store/modules/user-module";
import { ROUTE_NAMES } from "@/router/index";

export type AuthorizationRequirement = "REQUIRE_TOKEN" | "FORBID_TOKEN";

export const registerAuthorizationNavigationGuard = (router: VueRouter) => {
  router.beforeEach((to, from, next) => {
    const { authorization } = to.meta as {
      authorization: AuthorizationRequirement;
    };
    switch (authorization) {
      case "REQUIRE_TOKEN":
        userModule.isLoggedIn
          ? next()
          : next({
              name: ROUTE_NAMES.LOGIN,
              query: {
                "redirect-to": to.fullPath,
              },
            });
        break;
      case "FORBID_TOKEN":
        userModule.isLoggedIn
          ? next({ name: ROUTE_NAMES.INSTALLATION.LIST })
          : next();
        break;
      default:
        next();
    }
  });
};
