
















































import { ValidationObserver } from "vee-validate";
import { Component, Mixins } from "vue-property-decorator";
import {
  DefaBaseButton,
  DefaBaseRadioGroup,
  DefaBaseTextarea,
} from "@defa-as/components";
import type { RejectionReason } from "@defa-as/utils";
import StageGeneric from "@/components/stages/StageGeneric.vue";
import { StageId } from "@/store/model/progress";
import { optionsRejectionReason } from "@/config/options";
import { assignmentModule } from "@/store/modules/assignment-module";
import { progressModule } from "@/store/modules/progress-module";
import ModalConfirmMixin from "@/mixins/modal-confirm.mixin";
import BaseModalConfirm from "@/components/common/base/BaseModalConfirm.vue";
import BaseReportSection from "@/components/common/base/BaseReportSection.vue";

@Component({
  components: {
    BaseReportSection,
    ValidationObserver,
    BaseModalConfirm,
    StageGeneric,
    DefaBaseButton,
    DefaBaseTextarea,
    DefaBaseRadioGroup,
  },
})
export default class StageAssignmentRejected extends Mixins(ModalConfirmMixin) {
  readonly optionsRejectionReason = optionsRejectionReason();

  get rulesComment() {
    return this.assignmentModule.rejection.reason === "other"
      ? "required"
      : null;
  }

  get assignmentModule() {
    return assignmentModule;
  }

  get rejectionComment() {
    return this.assignmentModule.rejection.comment as string;
  }

  set rejectionComment(value: string) {
    this.assignmentModule.setRejectionProperty({ property: "comment", value });
  }

  onRejectionReasonChange(value: RejectionReason) {
    this.assignmentModule.setRejectionProperty({ property: "reason", value });
  }

  async goBack() {
    await progressModule.transitionStage({
      stageId: StageId.ASSIGNMENT_OPENED,
    });
  }

  async failAssignment() {
    this.hideModalConfirm();
    await progressModule.transitionStage({
      stageId: StageId.FAILED_REJECTED,
      event: "assignmentRejected",
      payload: {
        rejection: {
          reason: this.assignmentModule.rejection.reason,
          comment: this.assignmentModule.rejection.comment,
        },
      },
    });
  }
}
