import { render, staticRenderFns } from "./StageConsignmentStartedDeliveryAddress.vue?vue&type=template&id=13dcd79d&scoped=true&"
import script from "./StageConsignmentStartedDeliveryAddress.vue?vue&type=script&lang=ts&"
export * from "./StageConsignmentStartedDeliveryAddress.vue?vue&type=script&lang=ts&"
import style0 from "./StageConsignmentStartedDeliveryAddress.vue?vue&type=style&index=0&id=13dcd79d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13dcd79d",
  null
  
)

export default component.exports